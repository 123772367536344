import React from "react";
import { t } from "ttag";
import Flickity from "flickity-imagesloaded";
import {
    IProduct,
    IConcreteBundle,
} from "tsi-common-react/src/models/catalogue.interfaces";
import { MiniConfigurator } from "tsi-common-react/src/apps/configurator/components/MiniConfigurator";
import LoadingSpinner from "tsi-common-react/src/common/LoadingSpinner";
import { OutOfContextBundle } from "./OutOfContextBundle";
import { BundleGroupTypes } from "tsi-common-react/src/constants";

export interface IProps {
    rootProduct: IProduct | null;
    baseVariant: IProduct | null;
    bundles: IConcreteBundle[];
}

interface IState {}

export class OutOfContextBundleCollection extends React.Component<
    IProps,
    IState
> {
    private flickity: Flickity | null = null;
    private bundlesElem: HTMLDivElement | null = null;

    componentDidUpdate() {
        if (!this.bundlesElem) {
            return;
        }
        if (this.flickity) {
            this.flickity.destroy();
        }
        if (this.bundlesElem.childNodes.length > 1) {
            this.flickity = new Flickity(this.bundlesElem, {
                cellAlign: "left",
                wrapAround: true,
                imagesLoaded: true,
                arrowShape: {
                    x0: 10,
                    x1: 60,
                    y1: 50,
                    x2: 60,
                    y2: 45,
                    x3: 15,
                },
            });
        }
    }

    render() {
        // Show a loading spinner while loading data
        if (!this.props.rootProduct || !this.props.baseVariant) {
            return <LoadingSpinner />;
        }

        // If we have a base product but no bundles, don't display anything
        if (this.props.bundles.length <= 0) {
            return null;
        }

        const rootProduct = this.props.rootProduct;
        const baseVariant = this.props.baseVariant;

        const bundles = this.props.bundles
            .filter((bundle) => {
                const bundle_type = bundle.bundle_group.bundle_type;
                return (
                    bundle_type === BundleGroupTypes.DEFAULT ||
                    bundle_type === BundleGroupTypes.PDP_BUY_TOGETHER
                );
            })
            .map((bundle, i) => {
                return (
                    <OutOfContextBundle
                        key={i}
                        product={rootProduct}
                        variant={baseVariant}
                        bundle={bundle}
                    />
                );
            });

        return (
            <div>
                <div className="pdp-bundles-header">
                    <div className="pdp-bundles-header__title">
                        {t`Make Your Bed Even More Dreamy`}
                    </div>
                    <div className="pdp-bundles-header__desc">
                        {t`Choose a mattress & base combination for unlimited comfort, full-body ergonomic support, and even massage options. Remember: your Tempur-Pedic mattress needs a sturdy base.`}
                    </div>
                </div>
                <MiniConfigurator
                    rootProduct={rootProduct}
                    configType={"pdp-hero"}
                />
                <div
                    className="pdp-bundles__list"
                    ref={(ref) => {
                        this.bundlesElem = ref;
                    }}
                >
                    {bundles}
                </div>
            </div>
        );
    }
}
