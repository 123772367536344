import React from "react";
import { t } from "ttag";
import Modal from "react-modal";
import SVG from "react-inlinesvg";
import { IRetailStoreWithDistance } from "tsi-common-react/src/models/location.interfaces";
import { ISyncStoreID } from "tsi-common-react/src/models/nominals";
import { LocatorSendResults } from "./LocatorSendResults";
import iconXClose from "../../svg/x-close.svg";

interface IProps {
    modalClassName: string;
    bodyOpenClassName?: string;
    buttonLabel: string;
    buttonImgSrc: string;
    buttonImgAlt: string;
    modalTitle: string;
    modalDescription: string | React.ReactNode;
    inputType?: "email" | "phone";
    inputPlaceholder?: string;
    submitButtonLabel: string;
    maxSelected: number;
    stores: IRetailStoreWithDistance[];
    getStoreNumber: (store: IRetailStoreWithDistance) => number;
    onSubmit: (
        recipient: string,
        selectedStoreIDs: Set<ISyncStoreID>,
    ) => Promise<void>;
}

interface IState {
    isOpen: boolean;
    isSubmitting: boolean;
}

export class LocatorSendResultsModal extends React.Component<IProps, IState> {
    state: IState = {
        isOpen: false,
        isSubmitting: false,
    };

    private readonly onOpen = () => {
        this.setState({
            isOpen: true,
        });
    };

    private readonly onClose = () => {
        this.setState({
            isOpen: false,
        });
    };

    private readonly onSubmit = async (
        recipient: string,
        selected: Set<ISyncStoreID>,
    ) => {
        this.setState({
            isSubmitting: true,
        });
        try {
            await this.props.onSubmit(recipient, selected);
        } catch (e) {
            this.setState({
                isSubmitting: false,
                isOpen: true,
            });
        } finally {
            this.setState({
                isSubmitting: false,
                isOpen: false,
            });
        }
    };

    render() {
        return (
            <div
                className={`send__item modal modal-${this.props.modalClassName} store-locator-survey`}
            >
                <button onClick={this.onOpen}>
                    <span className="send__label">
                        {this.props.buttonLabel}
                    </span>
                    <span className="send__icon">
                        <img
                            alt={this.props.buttonImgAlt}
                            src={this.props.buttonImgSrc}
                        />
                    </span>
                </button>
                <Modal
                    aria={{
                        modal: true,
                    }}
                    style={{
                        content: {
                            top: "50%",
                            left: "50%",
                            transform: "translate(-50%, -50%)",
                            maxHeight: "95%",
                            maxWidth: "542px",
                            width: "96%",
                            overflow: "auto",
                            padding: "40px",
                        },
                    }}
                    contentLabel={this.props.modalTitle}
                    bodyOpenClassName={`ReactModal__Body--open ${this.props.bodyOpenClassName}`}
                    portalClassName="locator"
                    className={`locations modal ${this.props.modalClassName}`}
                    isOpen={this.state.isOpen}
                    onRequestClose={this.onClose}
                    role="dialog"
                >
                    <button
                        aria-label={t`Close`}
                        className="modal-close button--ghost"
                        onClick={this.onClose}
                    >
                        <SVG
                            aria-hidden="true"
                            className="modal-close__icon"
                            src={iconXClose}
                            title={t`Close Icon`}
                        />
                    </button>
                    <LocatorSendResults
                        title={this.props.modalTitle}
                        description={this.props.modalDescription}
                        inputType={this.props.inputType}
                        inputPlaceholder={this.props.inputPlaceholder}
                        submitButtonLabel={this.props.submitButtonLabel}
                        maxSelected={this.props.maxSelected}
                        stores={this.props.stores}
                        getStoreNumber={this.props.getStoreNumber}
                        isSubmitting={this.state.isSubmitting}
                        onCancel={this.onClose}
                        onSubmit={this.onSubmit}
                    />
                </Modal>
            </div>
        );
    }
}
