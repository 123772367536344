import React from "react";
import { t } from "ttag";
import classNames from "classnames";
import { TransitionGroup } from "react-transition-group";
import { MattressSizeOptions } from "../constants";
import { useMattressSizeState } from "../hooks/useMattressSizeState";
import styles from "./MattressSize.module.scss";
import { strings } from "../../../localization";

export const MattressSelectorSize = () => {
    const {
        onPrevSize,
        onNextSize,
        selectedSize,
        isNextDisabled,
        isPrevDisabled,
        sizeClass,
        width,
        height,
    } = useMattressSizeState();

    const isSplit = [
        MattressSizeOptions.SPLIT_KING,
        MattressSizeOptions.CAL_SPLIT_KING,
    ].includes(selectedSize);

    const currentSizeClasses = classNames({
        [styles.sizeSelector]: !isSplit,
        [sizeClass]: true,
        [styles.sizeBorder]: !isSplit,
    });

    return (
        <>
            <div className={styles.heading}>
                <h1>{strings.get("DISCOVER_YOUR_PERFECT_MATTRESS")}</h1>
                <p className={styles.instructions}>
                    {t`To get started, select your preferred mattress size.`}
                </p>
            </div>
            <div className={styles.sizeSelectorContainer}>
                <button
                    className={styles.decrementButton}
                    onClick={onPrevSize}
                    disabled={isPrevDisabled}
                >
                    &ndash;
                </button>
                <TransitionGroup className={currentSizeClasses}>
                    <div className={styles.widthMeasurement}>
                        {width}
                        &quot;
                    </div>
                    {isSplit ? (
                        <>
                            <div className="left"></div>
                            <div className="right"></div>
                        </>
                    ) : (
                        <div></div>
                    )}
                    <div className={styles.heightMeasurement}>
                        {height}
                        &quot;
                    </div>
                </TransitionGroup>
                <button
                    className={styles.incrementButton}
                    onClick={onNextSize}
                    disabled={isNextDisabled}
                >
                    +
                </button>
                <div className={styles.sizeLabel}>{selectedSize}</div>
            </div>
        </>
    );
};
