import React from "react";
import { t } from "ttag";
import ReactTooltip from "react-tooltip";
import SVG from "react-inlinesvg";
import { trapFocus } from "tsi-common-react/src/utils/keyboardFocus";
import iconQuestionMarkCircleFilled from "../../svg/question-mark-circle-filled.svg";

interface IProps {}

interface IState {
    isSmallScreen: boolean;
    isKeyDown: boolean;
    isTooltipOpen: boolean;
}

export class TooltipWhiteGlove extends React.Component<IProps, IState> {
    private readonly SMALL_SCREEN_WIDTH_THRESHOLD = 1366;

    state: IState = {
        isSmallScreen: false,
        isKeyDown: false,
        isTooltipOpen: false,
    };

    private readonly onAfterTooltipShow = () => {
        this.setState({
            isTooltipOpen: true,
        });
    };

    private readonly onAfterTooltipHide = () => {
        this.setState({
            isTooltipOpen: false,
            isKeyDown: false,
        });
    };

    componentDidMount() {
        this.checkScreenSize();
        window.addEventListener("resize", () => {
            this.checkScreenSize();
        });
    }

    componentDidUpdate() {
        const tooltip =
            this.state.isKeyDown &&
            document.querySelector(".product__delivery-tooltip.tooltip");
        if (tooltip) {
            tooltip.classList.add("key-down");
        }
        const activeTooltip = document.querySelector<HTMLElement>(
            ".product__delivery-tooltip.tooltip.show",
        );
        if (activeTooltip) {
            const activeCloseButton = activeTooltip.querySelector("button");
            if (activeCloseButton) {
                activeCloseButton.focus();
            }
            trapFocus(activeTooltip);
        }
    }

    private isSmallScreenWidth() {
        return window.innerWidth < this.SMALL_SCREEN_WIDTH_THRESHOLD;
    }

    private checkScreenSize() {
        this.setState({
            isSmallScreen: this.isSmallScreenWidth(),
        });
    }

    render() {
        const tipId = "product__delivery-status";
        return (
            <div className="tooltip-container">
                <p className="product__shipping-status--white-glove">
                    {t`White Glove Delivery`}
                </p>
                <button
                    aria-describedby={tipId}
                    aria-expanded={this.state.isTooltipOpen}
                    className="product__shipping-icon"
                    data-for={tipId}
                    data-tip={true}
                    title={t`Learn more`}
                    onMouseOver={() => {
                        this.setState({ isKeyDown: false });
                    }}
                >
                    <SVG
                        aria-hidden="true"
                        className="product__shipping-icon-item"
                        src={iconQuestionMarkCircleFilled}
                        title={t`Questions Icon`}
                    />
                </button>
                <ReactTooltip
                    afterHide={this.onAfterTooltipHide.bind(this)}
                    afterShow={this.onAfterTooltipShow.bind(this)}
                    aria-label={t`White Glove Delivery information`}
                    class="product__delivery-tooltip tooltip"
                    event={"mouseover focus"}
                    eventOff="scroll mousewheel mouseleave mouseout blur"
                    id={tipId}
                    place={this.state.isSmallScreen ? "left" : "bottom"}
                    role="tooltip"
                    isCapture={true}
                >
                    <p className="product__delivery-description">
                        {t`White Glove Delivery includes delivery, in-home set-up by one of our delivery partners and removal of your old mattress and box spring, if requested.`}
                    </p>
                </ReactTooltip>
            </div>
        );
    }
}
