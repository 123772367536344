import React from "react";
import { t } from "ttag";
import SVG from "react-inlinesvg";
import { slugify } from "../../../utils/text";
import {
    IFeelData,
    isFeelData,
    IStepData,
    OptionStepsType,
} from "../models.interfaces";
import classNames from "classnames";
import { RichText } from "../../../common/RichText";
import { FormCheckbox } from "../../../forms";
import styles from "./Option.module.scss";
import { SVGLoader } from "../elements/SVGLoader";
import { useStepInformation } from "../hooks/useStepInformation";
import { Step } from "../constants";

// Icons
import iconBackSleepers from "../../../../img/icons/mattress-selector/back-sleepers.svg";
import iconBudgetBalance from "../../../../img/icons/mattress-selector/budget-balance.svg";
import iconBudgetForever from "../../../../img/icons/mattress-selector/budget-forever.svg";
import iconBudgetPiggy from "../../../../img/icons/mattress-selector/budget-piggy.svg";
import iconCoolingHot from "../../../../img/icons/mattress-selector/cooling-hot.svg";
import iconCoolingMild from "../../../../img/icons/mattress-selector/cooling-mild.svg";
import iconCoolingNoHeat from "../../../../img/icons/mattress-selector/cooling-no-heat.svg";
import iconCouple from "../../../../img/icons/mattress-selector/couple.svg";
import iconExit from "../../../../img/icons/mattress-selector/exit.svg";
import iconFirm from "../../../../img/icons/mattress-selector/firm.svg";
import iconHybrid from "../../../../img/icons/mattress-selector/hybrid.svg";
import iconLightbulb from "../../../../img/icons/mattress-selector/lightbulb.svg";
import iconMedium from "../../../../img/icons/mattress-selector/medium.svg";
import iconMediumHybrid from "../../../../img/icons/mattress-selector/medium-hybrid.svg";
import iconMild from "../../../../img/icons/mattress-selector/mild.svg";
import iconNo from "../../../../img/icons/mattress-selector/no.svg";
import iconNoDisturb from "../../../../img/icons/mattress-selector/no-disturb.svg";
import iconNumberOne from "../../../../img/icons/mattress-selector/number-one.svg";
import iconNumberTwo from "../../../../img/icons/mattress-selector/number-two.svg";
import iconPartner from "../../../../img/icons/mattress-selector/partner.svg";
import iconPiggy from "../../../../img/icons/mattress-selector/piggy.svg";
import iconSideSleepers from "../../../../img/icons/mattress-selector/side-sleepers.svg";
import iconSleepProfile from "../../../../img/icons/mattress-selector/sleep-profile.svg";
import iconSnore from "../../../../img/icons/mattress-selector/snore.svg";
import iconSoft from "../../../../img/icons/mattress-selector/soft.svg";
import iconStomachSleepers from "../../../../img/icons/mattress-selector/stomach-sleepers.svg";

export const icons = {
    "back-sleepers": iconBackSleepers,
    "budget-balance": iconBudgetBalance,
    "budget-forever": iconBudgetForever,
    "budget-piggy": iconBudgetPiggy,
    "cooling-hot": iconCoolingHot,
    "cooling-mild": iconCoolingMild,
    "cooling-no-heat": iconCoolingNoHeat,
    "couple": iconCouple,
    "exit": iconExit,
    "firm": iconFirm,
    "hybrid": iconHybrid,
    "lightbulb": iconLightbulb,
    "medium": iconMedium,
    "medium-hybrid": iconMediumHybrid,
    "mild": iconMild,
    "no": iconNo,
    "no-disturb": iconNoDisturb,
    "number-one": iconNumberOne,
    "number-two": iconNumberTwo,
    "partner": iconPartner,
    "piggy": iconPiggy,
    "side-sleepers": iconSideSleepers,
    "sleep-profile": iconSleepProfile,
    "snore": iconSnore,
    "soft": iconSoft,
    "stomach-sleepers": iconStomachSleepers,
} as const;

const iconExists = (step: string): step is keyof typeof icons => {
    return Object.prototype.hasOwnProperty.call(icons, step);
};

const getIcon = (trackerStep: string): string | null => {
    const step = trackerStep.toLowerCase().replace(/\s+/g, "-");
    return iconExists(step) ? icons[step] : null;
};

interface IProps {
    step: keyof OptionStepsType;
}

export const MattressOption = (props: IProps) => {
    const { stepInfo, selectedLevel, selectedData, onClick } =
        useStepInformation({
            step: props.step,
        });
    if (!stepInfo) {
        return null;
    }

    const options = stepInfo.data;

    function buildOptions(option: IStepData) {
        const optionClasses = classNames({
            [styles.listItem]: true,
            [styles.checked]: selectedLevel === option.level,
        });
        const img = !isFeelData(option) ? option.icon : option.label;
        const icon = getIcon(slugify(img));
        return (
            <li key={option.label} className={optionClasses}>
                <FormCheckbox
                    className="radio"
                    checked={selectedLevel === option.level}
                    id={option.label}
                    name={option.label}
                    value={option.level.toString()}
                    onChange={() => {
                        onClick(option);
                    }}
                    required={true}
                    readOnly
                />
                <label htmlFor={option.label} aria-selected={false}>
                    {icon && (
                        <SVG
                            aria-hidden="true"
                            src={icon}
                            title={option.label}
                            className={props.step}
                            loader={<SVGLoader />}
                        />
                    )}
                    <div>{option.label}</div>
                </label>
            </li>
        );
    }

    function buildRecommendations(recommendation: string, idx: number) {
        const icon = getIcon(slugify(recommendation));
        return (
            <div key={idx} className={styles.recommendation}>
                {icon && (
                    <SVG
                        aria-hidden="true"
                        src={icon}
                        title={recommendation}
                        loader={<SVGLoader className="small" />}
                    />
                )}
                {recommendation}
            </div>
        );
    }

    const classes = classNames({
        [styles.list]: true,
        quad: options.length > 3,
    });
    return (
        <>
            <fieldset className={styles.fieldset}>
                <legend>
                    <span>{stepInfo.heading}</span>
                </legend>
                <ul className={classes}>{options.map(buildOptions)}</ul>
            </fieldset>
            <section className={styles.optionDescription}>
                <RichText
                    html={
                        selectedData?.description ||
                        (stepInfo.description ?? "")
                    }
                ></RichText>
            </section>

            {props.step === Step.FEEL && (
                <div className={styles.recommendations}>
                    {(selectedData as IFeelData)?.recommendations.length >
                        0 && (
                        <>
                            {" "}
                            {t`Recommended for:`}
                            <div className={styles.container}>
                                {(
                                    selectedData as IFeelData
                                ).recommendations.map((recommendation, idx) => {
                                    return buildRecommendations(
                                        recommendation,
                                        idx,
                                    );
                                })}
                            </div>
                        </>
                    )}
                </div>
            )}
        </>
    );
};
