import React from "react";
import { t } from "ttag";
import { IUserConfigurableBundle } from "../../../models/catalogue.interfaces";
import { range } from "../../../utils/functional";
import { ErrorBoundary } from "../../../common/ErrorBoundary";
import { money } from "../../../utils/format";
import { urls } from "../../../utils/urls";
import { Dinero } from "../../../utils/money";
import { ConfigureGiftStatus } from "../constants";
import { ConfigureGift } from "./ConfigureGift";
import ErrorModal from "../../../common/ErrorModal";
import { Image } from "../../../common/Image";
import { Link } from "../../../common/Link";
import classNames from "classnames";
import gifts from "../../../../img/checkout/gifts.png";

interface IProps {
    status: ConfigureGiftStatus;
    bundles: IUserConfigurableBundle[];
    onAddToBasket: (event: React.MouseEvent<HTMLElement>) => void;
    totalValue: Dinero;

    // Add to basket error modal
    addToBasketErrorOpen: boolean;
    addToBasketErrorReason: string;
    onCloseErrorModal: () => void;
}

interface IState {}

export class ConfigureGiftsWithPurchase extends React.Component<
    IProps,
    IState
> {
    private readonly onCloseErrorModal = (
        e: React.FormEvent<Element> | React.KeyboardEvent<Element>,
    ) => {
        e.preventDefault();
        this.props.onCloseErrorModal();
    };

    componentDidMount() {
        // Prevent browser from restoring scroll positions when using the back button
        if ("scrollRestoration" in history) {
            history.scrollRestoration = "manual";
        }
    }

    private userConfigurableBundles() {
        return this.props.bundles.length > 0;
    }

    private buildTotalValue() {
        if (!this.userConfigurableBundles()) {
            return;
        }

        const formattedValue = money(this.props.totalValue);
        return <p>{t`a ${formattedValue} value`}</p>;
    }

    private buildGifts() {
        let giftNumericalOrder = 0;
        return this.props.bundles.map((bundle, giftIdx) => {
            return range(bundle.quantity).map((qtyIdx) => {
                const giftID = `${bundle.id}-${giftIdx}-${qtyIdx}`;
                giftNumericalOrder++;
                return (
                    <ErrorBoundary key={giftID}>
                        <ConfigureGift
                            bundle={bundle}
                            giftID={giftID}
                            giftIdx={giftNumericalOrder}
                            qtyIdx={qtyIdx}
                        />
                    </ErrorBoundary>
                );
            });
        });
    }

    private buildErrorModal() {
        return (
            <ErrorModal
                name="configurator"
                isOpen={this.props.addToBasketErrorOpen}
                onRequestClose={this.onCloseErrorModal}
            >
                <p>
                    {t`Product could not be added to cart (${this.props.addToBasketErrorReason}).`}
                </p>
                <p>{t`Please adjust your selection and try again.`}</p>
            </ErrorModal>
        );
    }

    private buildCTA() {
        const isAdding =
            this.props.status === ConfigureGiftStatus.ADDING_TO_BASKET;
        const isComplete = this.props.status === ConfigureGiftStatus.COMPLETE;
        const isError =
            this.props.status === ConfigureGiftStatus.SELECTION_ERROR;
        const errorMsg = (
            <p>
                {t`Sorry, the gift(s) you selected are currently unavailable. Please adjust your selections.`}
            </p>
        );
        let buttonText: string = t`Add gifts to cart`;
        if (isAdding) {
            buttonText = t`Adding…`;
        } else if (isComplete) {
            buttonText = t`Done!`;
        }
        if (isError) {
            const configureGiftsWithPurchase =
                document.querySelector<HTMLElement>(
                    ".configure-gifts-with-purchase",
                );
            const unavailableMessage =
                configureGiftsWithPurchase &&
                configureGiftsWithPurchase.querySelectorAll<HTMLElement>(
                    ".configurator__unavailable",
                );
            const firstUnavailableMessage =
                unavailableMessage && unavailableMessage[0];
            Array.from(
                document.querySelectorAll<HTMLElement>(
                    ".configure-gift__content",
                ),
            ).forEach((elem) => {
                if (elem.contains(firstUnavailableMessage)) {
                    elem.scrollIntoView({ behavior: "smooth" });
                }
            });
        }

        return (
            <section className="configure-gifts-with-purchase__add">
                {isError ? errorMsg : null}
                <button
                    className="button button--primary button--full-width"
                    onClick={this.props.onAddToBasket}
                    disabled={isAdding || isComplete}
                >
                    {buttonText}
                </button>
                {this.buildTotalValue()}
            </section>
        );
    }

    private buildCtaWithNoProducts() {
        return (
            <section className="configure-gifts-with-purchase__add configure-gifts-with-purchase__add--no-configuration">
                <Link
                    href={urls.pageURL("basket-summary")}
                    target="_top"
                    className="button button--primary button--full-width"
                >
                    {t`Return to Cart`}
                </Link>
                <Link
                    href={urls.pageURL("home")}
                    target="_top"
                    className="button button--primary button--full-width button--inverse"
                >
                    {t`Continue Shopping`}
                </Link>
            </section>
        );
    }

    render() {
        const heading = this.userConfigurableBundles()
            ? t`Your cart qualifies for a <strong>free</strong> gift.<br>Please customize your item(s) below:`
            : t`Congratulations!<br>You have successfully selected your <strong>free</strong> gift`;
        const containerClassNames = classNames({
            "l-capped-width": true,
            "configure-gifts-with-purchase": true,
            "configure-gifts-with-purchase--no-configuration":
                !this.userConfigurableBundles(),
        });
        const giftIcon = gifts;
        return (
            <div className={containerClassNames}>
                <header>
                    <Image src={giftIcon} alt="" />
                    <p className="pre-header">{t`GIFT BUNDLE`}</p>
                    <h1 dangerouslySetInnerHTML={{ __html: heading }}></h1>
                    {this.buildTotalValue()}
                </header>
                {this.buildGifts()}
                {this.userConfigurableBundles()
                    ? this.buildCTA()
                    : this.buildCtaWithNoProducts()}
                {this.buildErrorModal()}
            </div>
        );
    }
}
