import { MattressSizeOptions, Step } from "./constants";

export interface ICustomerMatchPoint {
    optionValues: {
        firmness: number;
        budget_rank: number;
    };
}

export interface IMattressBodyType {
    level: number;
    value: string;
    label: string;
    icon: string;
}

export interface IMattressOptionConstant {
    level: number;
    value: string;
    valid?: boolean;
    icon?: string;
    label: string;
    description?: string;
    result?: string;
    recommendations?: string[];
}
export interface IMattressOption extends IMattressOptionConstant {
    type: string;
}
export interface ISizeSelection {
    selectedSize: MattressSizeOptions;
}
export interface IBudgetSelection {
    selectedBudget: number;
}
export interface IFirmnessSelection {
    selectedFirmness: number;
}
export interface ICoolnessSelection {
    selectedCoolness: number;
}
export interface IBodyTypeSelection {
    selectedHeight: number;
    selectedWeight: number;
}
export interface ISelections
    extends IBudgetSelection,
        IFirmnessSelection,
        ICoolnessSelection,
        IBodyTypeSelection,
        ISizeSelection {}

export interface IFeelData {
    level: number;
    value: string;
    label: string;
    recommendations: string[];
    description: string;
    result: string;
}
export interface ICoolnessData {
    level: number;
    value: string;
    valid: boolean;
    label: string;
    icon: string;
    description: string;
}
export interface IBudgetData {
    level: number;
    value: string;
    label: string;
    icon: string;
    description: string;
}

export type IStepData = IFeelData | ICoolnessData | IBudgetData;

export type OptionStepsType = Pick<typeof Step, "FEEL" | "COOL" | "BUDGET">;

export type StepInformationType = {
    [key in OptionStepsType[keyof OptionStepsType]]: {
        key:
            | keyof ICoolnessSelection
            | keyof IFirmnessSelection
            | keyof IBudgetSelection;
        heading: string | null;
        description: string | null;
        data: IStepData[];
    };
};

export function isFeelData(data: IStepData): data is IFeelData {
    return (
        "recommendations" in data && "description" in data && "result" in data
    );
}
