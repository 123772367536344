import React from "react";
import { t } from "ttag";
import { getChatConnector } from "tsi-common-react/src/apps/chat/index";
import { ChatLink } from "tsi-common-react/src/apps/chat/ChatLink";
import { urls } from "tsi-common-react/src/utils/urls";
import badgeChatWhite from "../../svg/badge-chat-white.png";

interface IProps {}

interface IState {
    isChatOnline: boolean;
}

export class ChatCard extends React.Component<IProps, IState> {
    state: IState = {
        isChatOnline: false,
    };

    async componentDidMount() {
        const { isOnline } = await getChatConnector().init();
        this.setState({
            isChatOnline: isOnline,
        });
    }

    render() {
        if (!this.state.isChatOnline) {
            return null;
        }
        return (
            <>
                <img src={badgeChatWhite} alt="" />
                <h3>
                    <strong>{t`Chat`}</strong>
                </h3>
                <p>
                    {t`Questions about our products or need advice before you buy? Chat with one of our specialists.`}
                </p>
                <ChatLink
                    chatOnlineText={t`Chat`}
                    chatOfflineLink={urls.pageURL("contact-link")}
                />
            </>
        );
    }
}
