import React from "react";
import { connect } from "react-redux";
import {
    ITabFeatureAttributeBlock_AttributeTab,
    ITabFeatureAttributeBlock_ModelTab,
} from "../models.interfaces";
import { TStateMapper } from "../../reducers.interfaces";
import { IViewport, BreakPoint } from "../../../models/screen.interfaces";
import { Image } from "../../../common/Image";
import { Flair } from "../../../common/Flair";
import { tabFeatureAttributeBlock } from "../elementIDs";
import styles from "./TabFeatureAttributeBlockModelTabImage.module.scss";

interface IOwnProps {
    attrIdx: number;
    attrTab: ITabFeatureAttributeBlock_AttributeTab;
    modelIdx: number;
    modelTab: ITabFeatureAttributeBlock_ModelTab;
    isSelected: boolean;
}

interface IReduxProps {
    viewport: IViewport;
}

interface IProps extends IOwnProps, IReduxProps {}

interface IState {}

export class TabFeatureAttributeBlockModelTabImageComponent extends React.Component<
    IProps,
    IState
> {
    render() {
        return (
            <article
                id={tabFeatureAttributeBlock.modelTabContent(
                    this.props.attrTab,
                    this.props.attrIdx,
                    this.props.modelTab,
                    this.props.modelIdx,
                )}
                role="tabpanel"
                tabIndex={0}
                aria-labelledby={tabFeatureAttributeBlock.modelTabButton(
                    this.props.attrTab,
                    this.props.attrIdx,
                    this.props.modelTab,
                    this.props.modelIdx,
                )}
                hidden={!this.props.isSelected}
            >
                {this.props.modelTab.flair?.content && (
                    <div className={styles.flair}>
                        <Flair
                            flair={this.props.modelTab.flair}
                            inline={true}
                        />
                    </div>
                )}
                <div className={styles.image}>
                    <Image
                        src={
                            this.props.viewport.width <= BreakPoint.SMALL &&
                            this.props.modelTab.image_mobile
                                ? this.props.modelTab.image_mobile.url
                                : this.props.modelTab.image.url
                        }
                        className="responsive-img"
                    />
                </div>
            </article>
        );
    }
}

const mapStateToProps: TStateMapper<"configurator", IReduxProps, IOwnProps> = (
    rootState,
    ownProps,
) => {
    return {
        viewport: rootState.common.viewport,
        ...ownProps,
    };
};

export const TabFeatureAttributeBlockModelTabImage = connect(mapStateToProps)(
    TabFeatureAttributeBlockModelTabImageComponent,
);
