import React from "react";
import classNames from "classnames";
import { Step } from "../constants";
import styles from "./MattressMatchNav.module.scss";
import { NavButton } from "../components/NavButton";

export const MattressMatchNav = () => {
    const classes = classNames({
        [styles.nav]: true,
    });
    return (
        <>
            <nav className={classes}>
                {Object.values(Step).map((step) => (
                    <NavButton key={step} step={step} styles={styles.button} />
                ))}
            </nav>
        </>
    );
};
