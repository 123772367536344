import React from "react";
import { t } from "ttag";
import { connect } from "react-redux";
import { IProductUUID } from "tsi-common-react/src/models/nominals";
import { IRetailStoreWithDistance } from "tsi-common-react/src/models/location.interfaces";
import { TDispatchMapper } from "tsi-common-react/src/apps/reducers.interfaces";
import { urls } from "tsi-common-react/src/utils/urls";
import { Link } from "tsi-common-react/src/common/Link";
import { IReduxState } from "../../reducers.interfaces";
import { Dispatchers } from "../dispatchers";
import { Store } from "../components/Store";
import { storesWithSelectedProductInStockSelector } from "../selectors";

interface IReduxProps {
    stores: IRetailStoreWithDistance[];
}

interface IOwnProps {
    product: IProductUUID;
}

interface IDispatchProps {
    dispatchers: Dispatchers;
}

type IProps = IReduxProps & IOwnProps & IDispatchProps;

interface IState {}

export class NearbyStoresProductContainer extends React.Component<
    IProps,
    IState
> {
    async componentDidMount() {
        if (this.props.product) {
            this.props.dispatchers.setSelectedProduct(this.props.product);
        }
    }

    private renderStores() {
        if (this.props.stores.length === 0) {
            return null;
        }
        // Limit to displaying 2 stores
        const stores = this.props.stores.slice(0, 2);
        return (
            <ul className="pdp-modal-stores">
                {stores.map((store) => {
                    return <Store key={`${store.external_id}`} store={store} />;
                })}
            </ul>
        );
    }

    render() {
        return (
            <div>
                {this.renderStores()}
                <div className="pdp-modal-store__footer">
                    <Link
                        className="pdp-modal__link al-more-ways-to-buy-see_all"
                        href={urls.pageURL("find-a-retailer")}
                    >
                        {t`See all Retailers`}
                    </Link>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (
    state: IReduxState,
    ownProps: IOwnProps,
): IReduxProps & IOwnProps => {
    return {
        ...ownProps,
        stores: storesWithSelectedProductInStockSelector(state),
    };
};

const mapDispatchToProps: TDispatchMapper<IDispatchProps> = (dispatch) => {
    const dispatchers = new Dispatchers(dispatch);
    return {
        dispatchers: dispatchers,
    };
};

export const NearbyStoresProduct = connect(
    mapStateToProps,
    mapDispatchToProps,
)(NearbyStoresProductContainer);
