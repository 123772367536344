import React from "react";
import { t, ngettext, msgid } from "ttag";
import { connect } from "react-redux";
import SVG from "react-inlinesvg";
import classNames from "classnames";
import { ILocation } from "tsi-common-react/src/models/location.interfaces";
import {
    IViewport,
    BreakPoint,
} from "tsi-common-react/src/models/screen.interfaces";
import { TStateMapper } from "tsi-common-react/src/apps/reducers.interfaces";
import {
    nearbyStoreSelector,
    preferredLocationSelector,
} from "tsi-common-react/src/apps/common/selectors";
import { urls } from "tsi-common-react/src/utils/urls";
import { Trans } from "tsi-common-react/src/common/Trans";
import { Link } from "tsi-common-react/src/common/Link";
import iconStoreLocatorFooter from "../../svg/store_locator_footer.svg";

interface IOwnProps {
    isSiteSearchOpen: boolean;
}

interface IReduxProps {
    viewport: IViewport;
    numStores: number;
    location: ILocation | null;
}

interface IProps extends IOwnProps, IReduxProps {}

interface IState {}

class NearbyStoresSearchContainer extends React.Component<IProps, IState> {
    private buildContent() {
        const shouldDisplayStores =
            this.props.numStores > 0 &&
            this.props.viewport.width > BreakPoint.MEDIUM;
        if (!shouldDisplayStores) {
            return <span>{t`Find a Retailer`}</span>;
        }
        const locationLabel = this.props.location
            ? this.props.location.formatted_address
            : "";
        return (
            <span className="search-nav__retailers--content">
                <Trans
                    fmtString={ngettext(
                        msgid`${this.props.numStores} store near <ZipCode>you</ZipCode>`,
                        `${this.props.numStores} stores near <ZipCode>you</ZipCode>`,
                        this.props.numStores,
                    )}
                    data={{
                        ZipCode: (content) => (
                            <span
                                key="1"
                                className="search-nav__retailers--location"
                            >
                                {locationLabel || content}
                            </span>
                        ),
                    }}
                />
            </span>
        );
    }

    render() {
        const wrapperClasses = classNames({
            "search-nav__retailers": true,
            "search-nav__retailers--hide": this.props.isSiteSearchOpen,
            "al-search-nav__link--find-a-retailer": true,
        });
        return (
            <Link
                href={urls.pageURL("find-a-retailer")}
                title={t`Find A Retailer Near You`}
                className={wrapperClasses}
            >
                <SVG
                    className="search-nav__retailers-icon search-nav__retailers-icon--desktop"
                    src={iconStoreLocatorFooter}
                    title={t`Locate a Store Icon`}
                />
                {this.buildContent()}
            </Link>
        );
    }
}

const mapStateToProps: TStateMapper<"retail", IReduxProps, IOwnProps> = (
    state,
    ownProps,
) => {
    return {
        viewport: state.common.viewport,
        numStores: nearbyStoreSelector(state).length,
        location: preferredLocationSelector(state),
        ...ownProps,
    };
};

export const NearbyStoresSearch = connect(mapStateToProps)(
    NearbyStoresSearchContainer,
);
