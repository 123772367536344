import React from "react";
import classNames from "classnames";
import { Link } from "../../common/Link";
import { Image } from "../../common/Image";
import { IImageURL, IWebPageURL } from "../../models/nominals";
import styles from "./PromoAd.module.scss";

export interface IMenuPromo {
    type: "nav_advert";
    value: {
        use_html_block: boolean;
        promo_period: boolean;
        external_link: IWebPageURL;
        copy: string;
        image: {
            url: IImageURL;
            id: string;
            title: string;
        };
        page: {
            slug: string;
            url: IWebPageURL;
        };
        html: string;
    };
}

interface IProps {
    promoArrayEl: IMenuPromo;
}

export const PromoAd = (props: IProps) => {
    const { promoArrayEl } = props;
    const link = promoArrayEl.value.page
        ? promoArrayEl.value.page.url
        : promoArrayEl.value.external_link;

    const renderContent = () => {
        if (promoArrayEl.value.use_html_block) {
            return (
                <div
                    dangerouslySetInnerHTML={{
                        __html: promoArrayEl.value.html,
                    }}
                />
            );
        }
        if (promoArrayEl.value.image) {
            return (
                <Image
                    alt={promoArrayEl.value.image.title}
                    src={promoArrayEl.value.image.url}
                />
            );
        }
        return (
            <div className={styles.container}>
                <div
                    className={styles.copy}
                    dangerouslySetInnerHTML={{
                        __html: promoArrayEl.value.copy,
                    }}
                />
            </div>
        );
    };

    const rootClassName = classNames({
        [styles.root]: true,
        [styles.rootSingleImage]: promoArrayEl.value.image,
        [styles.rootRawHtml]: promoArrayEl.value.use_html_block,
    });
    return link ? (
        <Link href={link} target="_top" className={rootClassName} tabIndex={0}>
            {renderContent()}
        </Link>
    ) : (
        <div className={rootClassName} tabIndex={0}>
            {renderContent()}
        </div>
    );
};
