import React, { useState, useEffect } from "react";
import { t } from "ttag";
import { Dinero } from "dinero.js";
import { useAppSelector } from "tsi-common-react/src/apps/reducers";
import {
    filterFinancingPlans,
    getLongestFinancingPlan,
} from "tsi-common-react/src/apps/checkout/utils";
import { urls } from "tsi-common-react/src/utils/urls";
import { Link } from "tsi-common-react/src/common/Link";
import { getDinero, getMonthlyPrice } from "tsi-common-react/src/utils/money";
import { format } from "tsi-common-react/src/utils/format";
import { FinancingModalTriggerBannerMinicart } from "tsi-common-react/src/apps/financing/FinancingModalTrigger";
import { isoMonths } from "tsi-common-react/src/models/nominals";

interface IProps {
    closeModal: () => void;
}

// TODO: Consolidate Financing Trigger components into one
export const FinancingModalTriggerMinicart = (props: IProps) => {
    const [termLength, setTermLength] = useState("1");
    const [termSuperscript, setTermSuperscript] = useState("");
    const [termThreshold, setTermThreshold] = useState("");

    const financingPlans = useAppSelector(
        (state) => state.checkout.data.financing_plans,
    );
    const basketData = useAppSelector((state) => state.checkout.data.basket);
    const pricePerMonth: Dinero = basketData
        ? getMonthlyPrice(
              getDinero(basketData.total_excl_tax),
              parseInt(termLength, 10),
          )
        : getDinero(0.0);
    const showFinancing: boolean =
        basketData && termThreshold
            ? parseFloat(basketData.total_excl_tax) >= parseFloat(termThreshold)
            : false;

    useEffect(() => {
        calcFinancePlan();
    }, []);

    const calcFinancePlan = () => {
        const longestPlan = getLongestFinancingPlan(
            filterFinancingPlans(financingPlans),
        );
        if (longestPlan && longestPlan.term_months > isoMonths.wrap(0)) {
            setTermLength(longestPlan.term_months.toString());
            setTermSuperscript(
                longestPlan.fine_print_superscript
                    ? longestPlan.fine_print_superscript
                    : "",
            );
            setTermThreshold(
                longestPlan.product_price_threshold
                    ? longestPlan.product_price_threshold
                    : "",
            );
        } else {
            const total = basketData
                ? parseFloat(basketData.total_excl_tax)
                : 0;

            const plansHTML = Array.from<HTMLElement>(
                document.querySelectorAll(".financing-plan-table__plan"),
            );
            const availablePlans = plansHTML.filter((plan) => {
                return total >= parseFloat(plan.dataset.threshold || "");
            });

            if (availablePlans.length) {
                const bestPlanLength =
                    availablePlans[availablePlans.length - 1].dataset.length;
                const bestPlanSuperscript =
                    availablePlans[availablePlans.length - 1].dataset
                        .superscript;
                const bestPlanThreshold =
                    availablePlans[availablePlans.length - 1].dataset.threshold;

                setTermLength(bestPlanLength ? bestPlanLength : "1");
                setTermSuperscript(
                    bestPlanSuperscript ? bestPlanSuperscript : "",
                );
                setTermThreshold(bestPlanThreshold ? bestPlanThreshold : "");
            }
        }
    };

    return (
        <div className="basket-popover-financing">
            {showFinancing && (
                <>
                    <div className="basket-popover-financing__title">
                        {t`or Special Financing`}
                    </div>
                    <div className="basket-popover-financing__terms">
                        {format.money(pricePerMonth)}/{t`mo`}
                        <sup>
                            <Link href={urls.pageURL("finance-link")}>2</Link>
                        </sup>
                        <span className="basket-popover-financing__terms--medium">
                            {t`for`}
                        </span>
                        {t`${termLength} Months`}
                        <sup>
                            <Link href={urls.pageURL("finance-link")}>
                                {termSuperscript}
                            </Link>
                        </sup>
                    </div>
                </>
            )}
            <FinancingModalTriggerBannerMinicart
                closeModal={props.closeModal}
            />
        </div>
    );
};
