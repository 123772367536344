import React from "react";
import { t } from "ttag";
import { connect } from "react-redux";
import { IRetailStoreWithDistance } from "tsi-common-react/src/models/location.interfaces";
import { formatDistance } from "tsi-common-react/src/apps/retail/helpers";
import { TStateMapper } from "tsi-common-react/src/apps/reducers.interfaces";
import { nearestFlagshipStoreSelector } from "../selectors";

interface IOwnProps {}

interface IReduxProps {
    store: IRetailStoreWithDistance | null;
}

type IProps = IOwnProps & IReduxProps;

interface IState {}

class DistanceToFlagshipStoreContainer extends React.Component<IProps, IState> {
    render() {
        let text: string = t`See Closest Location`;
        if (this.props.store && this.props.store.distance) {
            const formattedDist = formatDistance(this.props.store.distance);
            text = t`${formattedDist} miles away`;
        }
        return <>{text}</>;
    }
}

const mapStateToProps: TStateMapper<"retail", IReduxProps, IOwnProps> = (
    state,
    ownProps,
) => {
    return {
        ...ownProps,
        store: nearestFlagshipStoreSelector(state),
    };
};

export const DistanceToFlagshipStore = connect(mapStateToProps)(
    DistanceToFlagshipStoreContainer,
);
