import React from "react";
import { t } from "ttag";
import SVG from "react-inlinesvg";
import classNames from "classnames";
import iconIconSearch from "../../svg/icon-search.svg";

interface IProps {
    isSearchOpen: boolean;
    onOpenSearch: () => void;
    onCloseSearch: () => void;
}

interface IState {}

export class SiteSearchBar extends React.Component<IProps, IState> {
    private openSearchButton: HTMLButtonElement | null = null;
    private searchButton: HTMLButtonElement | null = null;

    componentDidUpdate(prevProps: IProps) {
        if (
            this.searchButton &&
            this.props.isSearchOpen &&
            !prevProps.isSearchOpen
        ) {
            this.searchButton.focus();
        }

        if (
            this.openSearchButton &&
            prevProps.isSearchOpen &&
            !this.props.isSearchOpen
        ) {
            this.openSearchButton.focus();
        }
    }

    render() {
        const buttonClasses = classNames({
            "site-search-bar__button": true,
            "site-search-bar__button--hide": this.props.isSearchOpen,
        });
        return (
            <div className="site-search-bar">
                <button
                    ref={(ref) => {
                        this.openSearchButton = ref;
                    }}
                    className={buttonClasses}
                    type="button"
                    onClick={this.props.onOpenSearch}
                >
                    <SVG src={iconIconSearch} role="img" aria-hidden="true" />
                    <span>{t`Search`}</span>
                </button>
                <form
                    action="/search/"
                    method="get"
                    aria-hidden={!this.props.isSearchOpen}
                    target="_top"
                >
                    <button
                        ref={(ref) => {
                            this.searchButton = ref;
                        }}
                        type="submit"
                        aria-label={t`Search`}
                    >
                        <SVG
                            src={iconIconSearch}
                            role="img"
                            aria-hidden="true"
                        />
                    </button>
                    <label
                        className="ada-screenreader-only"
                        htmlFor="top-search-nav-form"
                    >
                        {t`Search`}
                    </label>
                    <input
                        id="top-search-nav-form"
                        type="text"
                        name="query"
                        title="Search Tempur-Pedic"
                        placeholder={t`Search`}
                    />
                    <button
                        type="button"
                        onClick={this.props.onCloseSearch}
                        className="close"
                        aria-label={t`Close Search Container`}
                    >
                        <span aria-hidden="true">x</span>
                    </button>
                </form>
            </div>
        );
    }
}
