import React from "react";
import { t } from "ttag";
import { StoreCategory } from "tsi-common-react/src/api/retail";
import { RetailStoreEventType } from "tsi-common-react/src/constants";
import { IRetailStoreWithDistance } from "tsi-common-react/src/models/location.interfaces";
import { ISyncStoreID } from "tsi-common-react/src/models/nominals";
import { MarkerMap } from "tsi-common-react/src/apps/retail/containers/MarkerMap";
import { trackRetailStoreEvent } from "tsi-common-react/src/utils/analytics";
import { LocatorNearestStoresRow } from "../components/LocatorNearestStoresRow";
import { LocatorSendResultsEmail } from "./LocatorSendResults_Email";
import { LocatorSendResultsPrint } from "./LocatorSendResults_Print";
import { LocatorSendResultsText } from "./LocatorSendResults_Text";
import { NoResults } from "./NoResults";
import { groupStores } from "../utils";

interface IProps {
    stores: IRetailStoreWithDistance[];
    focusedStoreID: ISyncStoreID | null;
    onSelectStore: (storeID: ISyncStoreID | null) => void;
    showMore: boolean;
    disableGrouping?: boolean;
    noResultsText?: string;
}

interface IState {}

export class LocatorNearestStores extends React.PureComponent<IProps, IState> {
    private readonly mapElem = React.createRef<MarkerMap>();

    private readonly getStoreNumber = (store: IRetailStoreWithDistance) => {
        return this.props.stores.indexOf(store) + 1;
    };

    private readonly onBeforeInfoWindowOpen = (
        info: google.maps.InfoWindow,
        store: IRetailStoreWithDistance,
    ) => {
        const url = `/locations/states/${store.state}/cities/${store.city}/stores/${store.id}/`;
        const content = `
            <div class="loc__info-window">
                <div class="loc-name">
                    <a href="${url}" target="_blank" rel="noopener noreferrer" title=${store.name}>
                        ${store.name}
                    </a>
                </div>
                <div class="loc-content">
                    <div class="loc-address">${store.address}</div>
                </div>
            </div>
        `;
        info.setContent(content);
        this.props.onSelectStore(store.external_id);
    };

    private readonly onInfoWindowClose = () => {
        this.props.onSelectStore(null);
    };

    private readonly trackRetailStoreAnalytics = (
        storeGroup: {
            main: IRetailStoreWithDistance;
            secondary: IRetailStoreWithDistance[];
        },
        storeID: ISyncStoreID,
    ) => {
        const store =
            storeGroup.main.external_id === storeID
                ? storeGroup.main
                : storeGroup.secondary.find((s) => s.external_id === storeID);
        if (store) {
            trackRetailStoreEvent(
                store.data.classification_tempur === StoreCategory.FLAGSHIP
                    ? RetailStoreEventType.TEMPUR_STORE
                    : RetailStoreEventType.RETAIL_STORE,
                store,
            );
        }
    };

    private renderRows() {
        const groups = this.props.disableGrouping
            ? this.props.stores.map((store) => {
                  return {
                      main: store,
                      secondary: [],
                  };
              })
            : groupStores(this.props.stores);
        if (groups.length <= 0) {
            return (
                <NoResults
                    retailerName={this.props.noResultsText || t`stores`}
                />
            );
        }
        return groups.map((storeGroup) => {
            return (
                <LocatorNearestStoresRow
                    key={`${storeGroup.main.external_id}`}
                    store={storeGroup.main}
                    nestedStores={storeGroup.secondary}
                    selectedStoreID={this.props.focusedStoreID}
                    getStoreNumber={this.getStoreNumber}
                    onSelectStore={(storeID, event) => {
                        event.stopPropagation();
                        this.mapElem.current?.onActivateMarker(storeID);
                        this.trackRetailStoreAnalytics(storeGroup, storeID);
                    }}
                />
            );
        });
    }

    render() {
        return (
            <>
                <div className="locations__map-wrapper">
                    <div
                        className="locations__map"
                        role="region"
                        aria-label="map"
                    >
                        <MarkerMap
                            ref={this.mapElem}
                            stores={this.props.stores}
                            markerOptions={(store) => {
                                return {
                                    icon: {
                                        path: `M12.964,0.542c-6.895,0 -12.485,5.463 -12.485,12.201c0,3.118 5.324,12.063 7.134,15.251c2.198,3.869
                                        5.351,10.894 5.351,10.894c0,0 3.153,-7.025 5.351,-10.894c1.81,-3.188 7.134,-12.133 7.134,-15.251c0,-6.738
                                        -5.59,-12.201 -12.485,-12.201Z`,
                                        fillColor: "#3C4652",
                                        fillOpacity: 1,
                                        anchor: new google.maps.Point(10, 29),
                                        strokeWeight: 2,
                                        strokeColor: "#ffffff",
                                        scale: 1,
                                        labelOrigin: new google.maps.Point(
                                            14,
                                            14,
                                        ),
                                    },
                                    label: {
                                        text: `${this.getStoreNumber(store)}`,
                                        color: "#fff",
                                        fontSize: "12px",
                                        fontWeight: "light",
                                    },
                                };
                            }}
                            elemProps={{
                                id: "locations__map",
                                className: "bh-sl-map",
                            }}
                            mapOptions={() => {
                                return {
                                    scrollwheel: false,
                                    draggable: true,
                                    disableDefaultUI: false,
                                    clickableIcons: false,
                                    mapTypeId: "roadmap",
                                };
                            }}
                            onBeforeInfoWindowOpen={this.onBeforeInfoWindowOpen}
                            onInfoWindowClose={this.onInfoWindowClose}
                        />
                        <div className="locations__extras">
                            <div className="send">
                                <LocatorSendResultsEmail
                                    stores={this.props.stores}
                                    getStoreNumber={this.getStoreNumber}
                                />
                                <LocatorSendResultsPrint
                                    stores={this.props.stores}
                                    getStoreNumber={this.getStoreNumber}
                                />
                                <LocatorSendResultsText
                                    stores={this.props.stores}
                                    getStoreNumber={this.getStoreNumber}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <section className="locations__list-wrapper">
                    <ul className="locations__list list">
                        {this.renderRows()}
                    </ul>
                    {this.props.showMore && (
                        <div>
                            <a href="#all">{t`See More Results`}</a>
                        </div>
                    )}
                </section>
            </>
        );
    }
}
