import React from "react";
import { t } from "ttag";
import Flickity from "flickity-imagesloaded";
import classNames from "classnames";
import { loadWistiaPlayer } from "tsi-common-react/src/utils/wistia";

export interface ICarouselCell {
    imageURL: string;
    imageTitle: string;
    videoSRC: string;
    label: string;
}

interface IProps {
    images: ICarouselCell[];
}

interface IState {
    selectedCell: number;
}

export class FlagshipCarousel extends React.Component<IProps, IState> {
    private carousel: HTMLDivElement | null = null;
    private carouselFlickity: Flickity | null = null;

    public state: IState = {
        selectedCell: 1,
    };

    componentDidMount() {
        const carouselElem = this.carousel;
        if (carouselElem) {
            this.initFlickity(carouselElem);
        }
        loadWistiaPlayer();
    }

    componentDidUpdate() {
        if (this.carouselFlickity) {
            this.carouselFlickity.select(this.state.selectedCell);
        }
    }

    private pauseAllVideos() {
        window._wq.push({
            id: "_all",
            onReady: () => {
                const allVideos = window.Wistia.api.all();
                for (let i = 0; i < allVideos.length; i++) {
                    allVideos[i].pause();
                }
            },
        });
    }

    // ADA - Remove and Set tabindex attributes for any carousel cells that are not selected
    private setCellAttributes() {
        const cells = document.querySelectorAll(
            ".slideshow--default .slideshow__cell",
        );
        Array.from(cells).map((cell, index) => {
            if (index === this.state.selectedCell) {
                cell.removeAttribute("tabindex");
            } else {
                cell.setAttribute("tabindex", "-1");
            }
        });
    }

    private initFlickity(carousel: HTMLDivElement) {
        if (this.carouselFlickity) {
            this.carouselFlickity.destroy();
        }
        this.carouselFlickity = new Flickity(carousel, {
            cellSelector: ".slideshow__cell",
            cellAlign: "center",
            pageDots: false,
            adaptiveHeight: true,
            imagesLoaded: true,
        });

        this.carouselFlickity.select(this.state.selectedCell, true, true);
        this.setCellAttributes();

        this.carouselFlickity.on("change", () => {
            if (this.carouselFlickity) {
                this.setState({
                    selectedCell: this.carouselFlickity.selectedIndex,
                });
                this.pauseAllVideos();
                this.setCellAttributes();
            }
        });
    }

    private readonly selectedCell = (
        cellIndex: number,
        event: React.MouseEvent<HTMLElement>,
    ) => {
        if (event) {
            event.preventDefault();
        }
        this.setState({
            selectedCell: cellIndex,
        });
    };

    private buildCarouselDots() {
        const dots = this.props.images.map((img, index) => {
            const classes = classNames({
                "is-selected": index === this.state.selectedCell,
            });
            const counter = index + 1;
            const label = t`Slide ${counter} of ${this.props.images.length} - ${img.label}`;
            return (
                <button
                    key={`${index}`}
                    className={classes}
                    onClick={this.selectedCell.bind(this, index)}
                    aria-current={index === this.state.selectedCell}
                    aria-label={label}
                ></button>
            );
        });
        return <div className="slideshow__dots">{dots}</div>;
    }

    private buildCarouselCells() {
        const images = this.props.images.map((img, i) => {
            const imageVideo = img.videoSRC ? (
                <span
                    key={i}
                    dangerouslySetInnerHTML={{ __html: img.videoSRC }}
                ></span>
            ) : (
                <img key={i} src={img.imageURL} alt={img.imageTitle} />
            );
            return (
                <div key={i} className="slideshow__cell">
                    <div className="lead-in">
                        <h4>
                            <span className="slideshow__label type_x1point5">
                                {img.label}
                            </span>
                        </h4>
                    </div>
                    {imageVideo}
                </div>
            );
        });
        return images;
    }

    render() {
        return (
            <>
                <div
                    className="slideshow slideshow--default"
                    ref={(ref) => {
                        this.carousel = ref;
                    }}
                >
                    {this.buildCarouselCells()}
                </div>
                {this.buildCarouselDots()}
            </>
        );
    }
}
