import React from "react";
import { t } from "ttag";

interface IOwnProps {}

type IProps = IOwnProps;

interface IState {}

export class FlagshipReviewsLink extends React.Component<IProps, IState> {
    private readonly onScrollToReviews = (
        event: React.MouseEvent<HTMLElement>,
    ) => {
        if (event) {
            event.preventDefault();
        }
        const reviews = document.querySelector("#reviews");
        reviews?.scrollIntoView({ behavior: "smooth" });
    };

    render() {
        return (
            <button
                className="pullquote__link scroll-on-page-link"
                onClick={this.onScrollToReviews}
            >
                {t`See More Testimonials`} &#187;
            </button>
        );
    }
}
