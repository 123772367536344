import React from "react";
import { t } from "ttag";
import classNames from "classnames";
import { TransitionGroup, CSSTransition } from "react-transition-group";
import SVG from "react-inlinesvg";
import { IProductAPIURL } from "tsi-common-react/src/models/nominals";
import {
    IProduct,
    IBasket,
    IBasketLine,
} from "tsi-common-react/src/models/catalogue.interfaces";
import { TGetProductUpsell } from "tsi-common-react/src/apps/checkout/components/BasketLineFull";
import { isMattress } from "../../utils/catalogue";
import { BundleGroupTypes } from "tsi-common-react/src/constants";
import { getDinero } from "tsi-common-react/src/utils/money";

// 3 versions of the suggestion component exist for A/B testing purposes
import { BasketLineSuggestionA } from "./BasketLineSuggestionA";
import { BasketLineSuggestionB } from "./BasketLineSuggestionB";
import { BasketLineSuggestionC } from "./BasketLineSuggestionC";
import iconCircle from "../../svg/circle.svg";

enum UpsellVersion {
    A = 0,
    B = 1,
    C = 2,
}

interface IProps {
    version: UpsellVersion;
    basket: IBasket;
    line: IBasketLine;
    addBasketLine: (url: IProductAPIURL, qty: number) => void;
}

interface IState {
    suggestionIndex: number;
}

export class BasketLineSuggestions extends React.Component<IProps, IState> {
    public state: IState = {
        suggestionIndex: 0,
    };

    private readonly selectSuggestion = (
        index: number,
        event: React.MouseEvent<HTMLButtonElement>,
    ) => {
        event.preventDefault();
        this.setState({ suggestionIndex: index });
    };

    private prevSuggestion() {
        const i = Math.max(0, this.state.suggestionIndex - 1);
        this.setState({ suggestionIndex: i });
    }

    private nextSuggestion() {
        const products = this.listSuggestedProducts();
        const i = Math.min(products.length - 1, this.state.suggestionIndex + 1);
        this.setState({ suggestionIndex: i });
    }

    private listSuggestedProducts() {
        const line = this.props.line;
        if (!line.bundles) {
            return [];
        }

        // Create a mapping of products IDs in the basket to the price of the item
        const pids = this.props.basket.lines.map((l) => {
            return l.product.url;
        });

        // Flatten suggested products from bundles
        let products = line.bundles
            .filter((bundle) => {
                const bundle_type = bundle.bundle_group.bundle_type;
                return (
                    bundle_type === BundleGroupTypes.DEFAULT ||
                    bundle_type === BundleGroupTypes.IN_BASKET_ADD_ON
                );
            })
            .map((bundle) => {
                return bundle.suggested_products;
            })
            .reduce((memo, suggestions) => {
                return memo.concat(suggestions);
            }, []);

        // Figure out the minimum cost to include as a suggestion.
        const minSuggestionPrice = products.reduce((memo, product) => {
            const price = getDinero(product.price.cosmetic_excl_tax || "0.00");
            if (pids.indexOf(product.url) !== -1 && price.greaterThan(memo)) {
                return price;
            }
            return memo;
        }, getDinero(0));

        // Filter out products already in the basket
        products = products.filter((product) => {
            const isAlreadyInCart = pids.indexOf(product.url) !== -1;
            const isAboveSugestedPrice = getDinero(
                product.price.cosmetic_excl_tax || "0.00",
            ).greaterThan(minSuggestionPrice);
            if (!isAlreadyInCart && isAboveSugestedPrice) {
                pids.push(product.url);
                return true;
            }
            return false;
        });

        // If showing Upsell version C, filter out everything other than Ergo Plus
        if (this.props.version === UpsellVersion.C) {
            products = products.filter((p) => {
                return (
                    p.slug === "tempur-ergo-plus" ||
                    (p.parent && p.parent.slug === "tempur-ergo-plus")
                );
            });
        }

        // Sort from most expensive to least expensive
        products = products.sort((productA, productB) => {
            const priceA = getDinero(
                productA.price.cosmetic_excl_tax || "0.00",
            );
            const priceB = getDinero(
                productB.price.cosmetic_excl_tax || "0.00",
            );
            return priceB.subtract(priceA).toUnit();
        });

        return products;
    }

    // Should also be removed, builds Basket SuggestionComponent based off suggestion #
    private getSuggestion(product: IProduct) {
        if (this.props.version === UpsellVersion.B) {
            return (
                <BasketLineSuggestionB
                    line={this.props.line}
                    product={product}
                    basketTotal={getDinero(this.props.basket.total_incl_tax)}
                    addBasketLine={this.props.addBasketLine}
                />
            );
        }
        if (this.props.version === UpsellVersion.C) {
            return (
                <BasketLineSuggestionC
                    line={this.props.line}
                    product={product}
                    basketTotal={getDinero(this.props.basket.total_incl_tax)}
                    addBasketLine={this.props.addBasketLine}
                />
            );
        }
        return (
            <BasketLineSuggestionA
                line={this.props.line}
                product={product}
                basketTotal={getDinero(this.props.basket.total_incl_tax)}
                addBasketLine={this.props.addBasketLine}
            />
        );
    }

    render() {
        const products = this.listSuggestedProducts();
        const suggestionIndex = this.state.suggestionIndex;
        const product = products[suggestionIndex];

        if (!product) {
            return null;
        }

        const circles = products.map((_, index) => {
            const circleClasses = classNames({
                "basket-line__details__suggestion-controls__circle-item": true,
                "basket-line__details__suggestion-controls__circle-item--filled":
                    suggestionIndex !== index,
            });
            const pageNum = index + 1;
            const info = t`Page ${pageNum} of ${products.length}`;

            return (
                <button
                    aria-current={suggestionIndex !== index ? false : true}
                    aria-label={info}
                    className={circleClasses}
                    key={index}
                    onClick={this.selectSuggestion.bind(this, index)}
                >
                    <SVG
                        title={t`Circle`}
                        src={iconCircle}
                        role="img"
                        aria-labelledby="title"
                    />
                </button>
            );
        });

        const hasPrev = suggestionIndex > 0;
        const hasNext = suggestionIndex < products.length - 1;

        const includeCircles =
            this.props.version === UpsellVersion.A && products.length > 1;

        const suggestionControls = includeCircles ? (
            <div className="basket-line__details__suggestion-controls">
                <button
                    aria-label={t`Previous`}
                    onClick={() => {
                        this.prevSuggestion();
                    }}
                    disabled={!hasPrev}
                >
                    &lt;
                </button>
                <div className="basket-line__details__suggestion-controls__circles">
                    {circles}
                </div>
                <button
                    aria-label={t`Next`}
                    onClick={() => {
                        this.nextSuggestion();
                    }}
                    disabled={!hasNext}
                >
                    &gt;
                </button>
            </div>
        ) : null;

        const basketSuggestion = this.getSuggestion(product);

        return (
            <>
                <TransitionGroup>
                    <CSSTransition
                        key={`${product.url}`}
                        classNames="transition--fade"
                        timeout={{ exit: 500, enter: 500 }}
                    >
                        {basketSuggestion}
                    </CSSTransition>
                </TransitionGroup>
                {suggestionControls}
            </>
        );
    }
}

const getSuggestionNumber = (basket: IBasket, line: IBasketLine) => {
    let optionNumber: number = UpsellVersion.A;
    optionNumber = document.body.getAttribute("data-suggestion-version")
        ? Number(document.body.getAttribute("data-suggestion-version"))
        : optionNumber;

    // Determine if the ergo-plus is already in the cart. If so, we can't show BasketSuggestionC.
    const hasErgoPlusAlready = basket.lines.reduce((memo, l) => {
        return (
            !!memo ||
            l.product.slug === "tempur-ergo-plus" ||
            (!!l.product.parent && l.product.parent.slug === "tempur-ergo-plus")
        );
    }, false);

    // Don't show option 2 is the line isn't a mattress or if they have ergo-Plus in their cart already
    if (
        optionNumber === UpsellVersion.C &&
        (!isMattress(line.product) || hasErgoPlusAlready)
    ) {
        optionNumber = UpsellVersion.A;
    }

    return optionNumber;
};

export const getLineProductUpsell: TGetProductUpsell = (
    basket,
    line,
    addBasketLine,
) => {
    const version = getSuggestionNumber(basket, line);
    const content = (
        <BasketLineSuggestions
            version={version}
            basket={basket}
            line={line}
            addBasketLine={addBasketLine}
        />
    );
    return {
        placement: version === UpsellVersion.C ? "post-details" : "in-details",
        hideBottomBorder: version === UpsellVersion.C,
        content: content,
    };
};
