import React from "react";
import { t } from "ttag";
import {
    BodyTypeData,
    CoolnessOptions,
    MattressSizeOptions,
    Step,
    stepInformation,
    stepOrder,
} from "../constants";
import classNames from "classnames";
import styles from "./NextButton.module.scss";
import { useAppDispatch, useAppSelector } from "../../reducers";
import { getResultsFromPreferences, onNavigate } from "../reducers";
import { IMattressMatchPreferenceSet } from "../../../models/catalogue.interfaces";
import { OptionStepsType } from "../models.interfaces";

export const NextButton = () => {
    const dispatch = useAppDispatch();
    const { selections, currentScreen } = useAppSelector(
        (state) => state.mattressmatch.ui,
    );

    const loadResults = () => {
        const cooling = stepInformation[Step.COOL].data.find(
            (item) => item.level === selections.selectedCoolness,
        )?.value;
        const data: IMattressMatchPreferenceSet = {
            preferred_feel:
                stepInformation[Step.FEEL].data.find(
                    (item) => item.level === selections.selectedFirmness,
                )?.value || "",
            height:
                BodyTypeData.height.find(
                    (item) => item.level === selections.selectedHeight,
                )?.value || "",
            weight:
                BodyTypeData.weight.find(
                    (item) => item.level === selections.selectedWeight,
                )?.value || "",
            cooling: cooling ? cooling !== CoolnessOptions.NEVER : false, // If the received value indicates sometimes or always sleep hot, those inputs will be weighted the same and be accepted as true for cooling while matching conditions.
            price_level:
                stepInformation[Step.BUDGET].data.find(
                    (item) => item.level === selections.selectedBudget,
                )?.value || "",
        };
        dispatch(getResultsFromPreferences(data));
    };

    const navigateTo = (nextScreen: number) => {
        if (stepOrder[currentScreen + 1] === Step.RESULTS) {
            loadResults();
        }
        dispatch(
            onNavigate({
                currentScreen: nextScreen,
            }),
        );
    };

    function isDisabled() {
        switch (stepOrder[currentScreen]) {
            case Step.SIZE:
            case Step.RESULTS:
                return false;
            case Step.BODY_TYPE:
                return (
                    selections.selectedHeight === 0 ||
                    selections.selectedWeight === 0
                );
            default:
                const step = stepOrder[currentScreen];
                return (
                    selections[
                        stepInformation[step as keyof OptionStepsType].key
                    ] === 0
                );
        }
    }
    const disabled = isDisabled();
    const buttonClasses = classNames({
        [styles.button]: true,
    });
    return (
        <div className={`${styles.buttonContainer} next-button-container`}>
            <button
                className={buttonClasses}
                disabled={disabled}
                onClick={() => {
                    navigateTo(currentScreen + 1);
                }}
            >
                Next
            </button>
            {currentScreen === stepOrder.indexOf(Step.SIZE) &&
                [
                    MattressSizeOptions.TWIN,
                    MattressSizeOptions.FULL,
                    MattressSizeOptions.CAL_SPLIT_KING,
                ].includes(selections.selectedSize) && (
                    <p>
                        {t`Please note: This size may not be available in all
                        models.`}
                    </p>
                )}
        </div>
    );
};
