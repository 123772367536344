import React from "react";
import { t } from "ttag";
import classNames from "classnames";
import {
    ILocation,
    IRetailStoreWithDistance,
} from "tsi-common-react/src/models/location.interfaces";
import { ISyncStoreID } from "tsi-common-react/src/models/nominals";
import { notEmpty } from "tsi-common-react/src/utils/functional";
import { Trans } from "tsi-common-react/src/common/Trans";
import { UncontrolledPopover } from "tsi-common-react/src/common/Popover";
import { formatDistance } from "tsi-common-react/src/apps/retail/helpers";
import { MarkerMap } from "tsi-common-react/src/apps/retail/containers/MarkerMap";
import { ICMSFlagshipStorePage } from "../models.interfaces";
import { LocatorSearch } from "../containers/LocatorSearch";
import { FlagshipNearestStoresTile } from "../components/FlagshipNearestStoresTile";
import fsMarker from "../../img/retail-locator/fs-marker.png";

interface IProps {
    stores: IRetailStoreWithDistance[];
    storePages: ICMSFlagshipStorePage[];
    maxTiles: number;
    preferredLocation: ILocation | null;
    includeLocationInfo: boolean;
    onAfterClose?: () => void;
    onAfterOpen?: () => void;
}

interface IState {
    focusedTileID: ISyncStoreID | null;
}

export class FlagshipNearestStores extends React.Component<IProps, IState> {
    state: IState = {
        focusedTileID: null,
    };

    private readonly defaultMapCenter = {
        lat: 37.0902,
        lng: -95.7129,
    };
    private readonly defaultZoom = 4;
    private readonly mapElem = React.createRef<MarkerMap>();
    private readonly popoverElem = React.createRef<UncontrolledPopover>();

    private readonly onBeforeInfoWindowOpen = (
        info: google.maps.InfoWindow,
        store: IRetailStoreWithDistance,
    ) => {
        const url = `/locations/states/${store.state}/cities/${store.city}/stores/${store.id}/`;
        const content = `<div className="loc__info-window">
            <a href="${url}">
                ${store.city}, ${store.state}
            </a>
        </div>`;
        info.setContent(content);
        this.setState({
            focusedTileID: store.external_id,
        });
    };

    private readonly onInfoWindowClose = () => {
        this.setState({
            focusedTileID: null,
        });
    };

    private getFormattedDistInMiles() {
        if (this.props.stores.length <= 0) {
            return t`Unknown`;
        }
        const formattedDist = formatDistance(this.props.stores[0].distance);
        return t`${formattedDist} miles.`;
    }

    private renderFilter() {
        const locationLabel =
            this.props.preferredLocation?.formatted_address_long ||
            this.props.preferredLocation?.formatted_address ||
            t`you`;
        const distInMiles = this.getFormattedDistInMiles();
        const modalClasses = classNames({
            "filters__dropdown filters__dropdown--location": true,
            "filters__dropdown--location-sm": true,
        });
        return (
            <section className="filters">
                <p className="filters__filters filters__filters--small">
                    <Trans
                        fmtString={t`<Dark>Closest</Dark> Tempur-Pedic<Reg>®</Reg> Store to <Location></Location> is <Distance></Distance>`}
                        data={{
                            Dark: (content) => (
                                <strong key="strong">{content}</strong>
                            ),
                            Reg: (content) => <sup key="sup">{content}</sup>,
                            Location: () => (
                                <UncontrolledPopover
                                    key="location"
                                    ref={this.popoverElem}
                                    triggerContent={
                                        <>
                                            <span className="ada-screenreader-only">
                                                {t`Change your location to find retailers in that area.`}
                                            </span>
                                            {locationLabel}
                                        </>
                                    }
                                    triggerBtnProps={{
                                        className:
                                            "filters__filter origin-location al-store-locator__origin-location",
                                    }}
                                    modalProps={{
                                        contentLabel: locationLabel,
                                    }}
                                >
                                    <div className={modalClasses}>
                                        <LocatorSearch
                                            onSubmit={() => {
                                                if (this.popoverElem.current) {
                                                    this.popoverElem.current.requestClose();
                                                }
                                            }}
                                        />
                                    </div>
                                </UncontrolledPopover>
                            ),
                            Distance: () => (
                                <strong
                                    key="distance"
                                    className="bh-sl-nearest-results"
                                >
                                    {distInMiles}
                                </strong>
                            ),
                        }}
                    />
                </p>
            </section>
        );
    }

    private findCMSStore(
        externalID: ISyncStoreID,
    ): ICMSFlagshipStorePage | null {
        const cmsStore = this.props.storePages.find((storePage) => {
            return storePage.meta.sync_id === externalID;
        });
        return cmsStore || null;
    }

    private renderTiles() {
        const storesIDs: Set<ISyncStoreID> = new Set();
        const tiles = this.props.stores
            // safety dedupe
            .reduce<IRetailStoreWithDistance[]>((memo, s) => {
                if (!storesIDs.has(s.external_id)) {
                    storesIDs.add(s.external_id);
                    memo.push(s);
                }
                return memo;
            }, [])
            .map((store) => {
                const storePage = this.findCMSStore(store.external_id);
                if (!storePage) {
                    return null;
                }
                return (
                    <FlagshipNearestStoresTile
                        key={`${store.external_id}`}
                        {...this.props}
                        store={store}
                        cmsProps={storePage}
                        isFocused={
                            store.external_id === this.state.focusedTileID
                        }
                        includeLocationInfo={this.props.includeLocationInfo}
                        onActivateMarker={() => {
                            this.mapElem.current?.onActivateMarker(
                                store.external_id,
                            );
                        }}
                    />
                );
            })
            .filter(notEmpty)
            .slice(0, this.props.maxTiles);
        return tiles;
    }

    render() {
        return (
            <>
                {this.renderFilter()}
                <MarkerMap
                    ref={this.mapElem}
                    stores={this.props.stores}
                    markerOptions={() => {
                        return {
                            icon: fsMarker,
                        };
                    }}
                    elemProps={{
                        "id": "locations__map",
                        "className": "locations__map",
                        "role": "region",
                        "aria-label": "map",
                    }}
                    mapOptions={() => {
                        return {
                            center: this.defaultMapCenter,
                            zoom: this.defaultZoom,
                            scrollwheel: false,
                            draggable: true,
                            disableDefaultUI: false,
                            clickableIcons: false,
                            mapTypeId: google.maps.MapTypeId.ROADMAP,
                            styles: [
                                {
                                    featureType: "all",
                                    elementType: "labels",
                                    stylers: [{ visibility: "on" }],
                                },
                                {
                                    featureType: "water",
                                    elementType: "geometry",
                                    stylers: [
                                        { visibility: "simplified" },
                                        { color: "#58585b" },
                                    ],
                                },
                                {
                                    featureType: "landscape",
                                    elementType: "all",
                                    stylers: [
                                        { visibility: "simplified" },
                                        { color: "#f2f2f2" },
                                    ],
                                },
                                {
                                    featureType: "water",
                                    elementType: "labels",
                                    stylers: [{ visibility: "off" }],
                                },
                                {
                                    featureType: "administrative.country",
                                    elementType: "labels",
                                    stylers: [
                                        {
                                            visibility: "off",
                                        },
                                    ],
                                },
                                {
                                    featureType: "administrative.locality",
                                    stylers: [
                                        {
                                            visibility: "off",
                                        },
                                    ],
                                },
                            ],
                        };
                    }}
                    onBeforeInfoWindowOpen={this.onBeforeInfoWindowOpen}
                    onInfoWindowClose={this.onInfoWindowClose}
                />
                <section
                    className="locations__list-wrapper l-capped-width"
                    role="region"
                    aria-label="Locations"
                >
                    <ul className="locations__list locations__list--index list">
                        {this.renderTiles()}
                    </ul>
                </section>
            </>
        );
    }
}
