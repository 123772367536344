import React from "react";
import { t } from "ttag";
import { IProduct } from "../../../models/catalogue.interfaces";
import { ConfiguratorFinancing } from "../../../common/ConfiguratorFinancing";
import { ConfiguratorProductPrice } from "../../../common/ConfiguratorProductPrice";
import { Image } from "../../../common/Image";
import { Link } from "../../../common/Link";
import { DiscountDisplayMode } from "../../configurator/constants";
import { ValueProps } from "../components/ValueProps";
import styles from "./Result.module.scss";
import RichText from "../../../common/RichText";
import { addProductToBasket } from "../../../apps/configurator/actions";
import { trackAddToBasketEvent } from "../../../utils/analytics";
import urls from "../../../utils/urls";

interface IOwnProps {
    product: IProduct;
    productImg: string;
    productCopy: string;
}

interface IProps extends IOwnProps {}

export const Result = (props: IProps) => {
    const onAddToBasket = async (
        event: React.MouseEvent<HTMLElement>,
        product: IProduct,
    ) => {
        event.preventDefault();
        try {
            await addProductToBasket(product.url);
            trackAddToBasketEvent(product);
            urls.navigateTo("basket-summary");
        } catch (e) {
            return;
        }
    };

    const attrs = props.product?.attributes;

    return (
        <div className={styles.result}>
            <div className={styles.header}>
                <span className={styles.productTitle}>
                    {props.product.parent?.title}
                </span>
                <p className={styles.productFeel}>{attrs.option_feel?.value}</p>
            </div>
            <div className={styles.productInfo}>
                <div className={styles.imageContainer}>
                    <Image
                        data-src={props.productImg}
                        className={styles.productImage}
                        alt=""
                        lazy={true}
                    />
                </div>
                <div className={styles.priceWrapper}>
                    <ConfiguratorProductPrice
                        price={props.product?.price}
                        strikeThroughMSRP={false}
                        discountDisplayMode={DiscountDisplayMode.SHOW_ABSOLUTE}
                        actualPriceStyle={"match"}
                    ></ConfiguratorProductPrice>
                    <ConfiguratorFinancing
                        price={props.product?.price}
                        variant={props.product}
                    ></ConfiguratorFinancing>
                </div>
                <Link
                    className={styles.detailsButton}
                    target="_top"
                    href={props.product.link}
                >
                    {t`Product Details`}
                </Link>
                <button
                    className={styles.addButton}
                    onClick={(e) => {
                        onAddToBasket(e, props.product);
                    }}
                >
                    {t`Add to Cart`}
                </button>
                {props.product.value_props && <ValueProps />}
            </div>
            <div className={styles.optionContainer}>
                <h1>{t`Why you'll love it.`}</h1>
                <ul>
                    <RichText html={props.productCopy} />
                </ul>
            </div>
        </div>
    );
};
