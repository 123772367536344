import { t } from "ttag";
import { StepInformationType } from "./models.interfaces";
import { strings } from "../../localization";
import iconShort from "../../../img/icons/mattress-selector/short.svg";
import iconWeight from "../../../img/icons/mattress-selector/weight.svg";
import iconLight from "../../../img/icons/mattress-selector/light.svg";
import iconHeight from "../../../img/icons/mattress-selector/height.svg";
import iconTall from "../../../img/icons/mattress-selector/tall.svg";
import iconHeavy from "../../../img/icons/mattress-selector/heavy.svg";

export enum MattressSizeOptions {
    TWIN = "twin",
    FULL = "full",
    QUEEN = "queen",
    KING = "king",
    CAL_KING = "california king",
    SPLIT_KING = "split king",
    CAL_SPLIT_KING = "california split king",
}
export enum FirmnessOptions {
    FIRM = "firm",
    MEDIUM = "medium",
    MEDIUM_HYBRID = "medium_hybrid",
    SOFT = "soft",
}
export enum PriceLevelOptions {
    BUDGET = "budget",
    BALANCED = "balanced",
    LIMITLESS = "limitless",
}
export enum HeightOptions {
    HEIGHT_5FT = "lt_5ft",
    HEIGHT_5_6FT = "eq_5_6ft",
    HEIGHT_6FT = "gt_6ft",
}
export enum WeightOptions {
    WEIGHT_150LBS = "lt_150lbs",
    WEIGHT_150_200LBS = "eq_150_200lbs",
    WEIGHT_250LBS = "gt_250lbs",
}
export enum CoolnessOptions {
    NEVER = "never",
    SOMETIMES = "sometimes",
    ALWAYS = "always",
}
export enum Step {
    SIZE = "SIZE",
    FEEL = "FEEL",
    BODY_TYPE = "BODY_TYPE",
    COOL = "COOL",
    BUDGET = "BUDGET",
    RESULTS = "RESULTS",
}
export enum Recommendations {
    BACK = "Back Sleepers",
    STOMACH = "Stomach Sleepers",
    SIDE = "Side Sleepers",
}
export enum FetchResultsStatusType {
    IDLE = "idle",
    LOADING = "loading",
    SUCCESS = "success",
    ERROR = "error",
}
export const stepOrder = [
    Step.SIZE,
    Step.FEEL,
    Step.BODY_TYPE,
    Step.COOL,
    Step.BUDGET,
    Step.RESULTS,
] as const;

export const stepInformation: StepInformationType = {
    [Step.FEEL]: {
        key: "selectedFirmness",
        heading: strings.get("MATTRESS_SELECTOR_FEEL_HEADING"),
        data: [
            {
                level: 1,
                value: FirmnessOptions.FIRM,
                label: t`Firm`,
                recommendations: [Recommendations.BACK],
                description: t`Our firm feel features a solid, "on top of the bed" feeling all night long.`,
                result: t`If you prefer a firmer feel, a firm will provide pressure relief with solid support.`,
            },
            {
                level: 2,
                value: FirmnessOptions.MEDIUM,
                label: t`Medium`,
                recommendations: [
                    Recommendations.STOMACH,
                    Recommendations.BACK,
                    Recommendations.SIDE,
                ],
                description: t`Our medium feel works to support and cradle your body all night long.`,
                result: t`If you prefer a medium feel, a medium will provide the conforming comfort you need while supporting your pressure points.`,
            },
            {
                level: 3,
                value: FirmnessOptions.MEDIUM_HYBRID,
                label: t`Medium Hybrid`,
                recommendations: [Recommendations.STOMACH],
                description: t`Our medium hybrid feel combines adaptive TEMPUR® Material and 1000+ premium coils for more effortless movement.`,
                result: t`If you prefer a softer feel, a medium-hybrid will provide soothing softness while supporting your pressure points.`,
            },
            {
                level: 4,
                value: FirmnessOptions.SOFT,
                label: t`Soft`,
                recommendations: [Recommendations.SIDE],
                description: t`Our softest feel allows the mattress to adjust easier to your shape for a conforming feel all night.`,
                result: t`If you prefer a softer feel, our soft will adjust easier to your shape for a conforming feel all night.`,
            },
        ],
        description: strings.get("MATTRESS_SELECTOR_FEEL_DEFAULT_DESCRIPTION"),
    },
    [Step.COOL]: {
        key: "selectedCoolness",
        heading: strings.get("MATTRESS_SELECTOR_COOL_HEADING"),
        data: [
            {
                level: 1,
                value: CoolnessOptions.NEVER,
                valid: false,
                label: "Never",
                icon: "cooling-no-heat",
                description: "",
            },
            {
                level: 2,
                value: CoolnessOptions.SOMETIMES,
                valid: false,
                label: "Sometimes",
                icon: "cooling-mild",
                description: t`Warm sleeper? No problem. We'll build your personalized sleep solution to feel cooler.`,
            },
            {
                level: 3,
                value: CoolnessOptions.ALWAYS,
                valid: true,
                label: "Always",
                icon: "cooling-hot",
                description: t`Sleep hot? No problem. We'll build your personalized sleep solution to feel up to 10° cooler.`,
            },
        ],
        description: strings.get("MATTRESS_SELECTOR_COOL_DEFAULT_DESCRIPTION"),
    },
    [Step.BUDGET]: {
        key: "selectedBudget",
        heading: strings.get("MATTRESS_SELECTOR_BUDGET_HEADING"),
        data: [
            {
                level: 1,
                value: PriceLevelOptions.BUDGET,
                icon: "budget-piggy",
                description: t`We know you value your sleep, so we'll build your sleep solution with our iconic innovations.`,
                label: "On a Budget",
            },
            {
                level: 2,
                value: PriceLevelOptions.BALANCED,
                icon: "budget-balance",
                description: t`Getting sleep is essential, so we'll build your sleep solution with our more advanced models.`,
                label: "A Good Balance",
            },
            {
                level: 3,
                value: PriceLevelOptions.LIMITLESS,
                icon: "budget-forever",
                description: t`Sleep is your number one priority, so your sleep solution will feature our most premium options.`,
                label: "Limitless",
            },
        ],
        description: strings.get(
            "MATTRESS_SELECTOR_BUDGET_DEFAULT_DESCRIPTION",
        ),
    },
};

export const BodyTypeData = {
    height: [
        {
            level: 1,
            value: HeightOptions.HEIGHT_5FT,
            label: "- 5FT",
            icon: iconShort,
        },
        {
            level: 2,
            value: HeightOptions.HEIGHT_5_6FT,
            label: "5-6 FT",
            icon: iconHeight,
        },
        {
            level: 3,
            value: HeightOptions.HEIGHT_6FT,
            label: "6FT +",
            icon: iconTall,
        },
    ],
    weight: [
        {
            level: 1,
            value: WeightOptions.WEIGHT_150LBS,
            label: "- 150 lbs",
            icon: iconLight,
        },
        {
            level: 2,
            value: WeightOptions.WEIGHT_150_200LBS,
            label: "150-250 lbs",
            icon: iconWeight,
        },
        {
            level: 3,
            value: WeightOptions.WEIGHT_250LBS,
            label: "250 lbs +",
            icon: iconHeavy,
        },
    ],
};
