import React from "react";
import { t } from "ttag";
import SVG from "react-inlinesvg";
import Trans from "tsi-common-react/src/common/Trans";
import iconTempurMeMattress from "../../svg/tempur-me-mattress.svg";

interface IProps {}

interface IState {}

export class TempurMeFooterSignup extends React.Component<IProps, IState> {
    render() {
        return (
            <a
                href="http://register.tempurpedic.com/tempurme"
                target="_blank"
                className="footer-offers__header site-footer__header"
                rel="noreferrer"
            >
                <Trans
                    fmtString={t`Join <Underline>TEMPUR-ME</Underline> <PromoIcon></PromoIcon> and get $300 in FREE Accessories`}
                    data={{
                        Underline: (content) => (
                            <span
                                key="1"
                                className="site-footer__header--underline"
                            >
                                {content}
                            </span>
                        ),
                        PromoIcon: () => (
                            <SVG
                                key="2"
                                className="footer-offers__header-icon"
                                src={iconTempurMeMattress}
                                title={t`Mattress Icon`}
                            />
                        ),
                    }}
                />
            </a>
        );
    }
}
